import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';

import Alert from '@common/components/Alert';
import WaveTextField from '@common/components/form/WaveTextField';
import WaveSelectField from '@common/components/form/WaveSelectField';
import WaveTextArea from '@common/components/form/WaveTextArea';
import WorkshopReminder from '@common/config/Tooltip/WorkshopReminder';
import InfoIcon from '@common/icons/InfoIcon';
import ApiService from '@common/services/ApiService';
import MetadataService from '@common/services/MetadataService';
import { formHelperHoc } from '@common/components/form-helper-hoc';
import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import { notificationStyles } from './styles';
import { DefaultCheckbox } from '@common/components/default-checkbox';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.meta = new MetadataService();
    this.state = {
      alertOpen: false,
      serverity: 'success',
      alertMessage: 'Success!'
    };
  }

  handleInputChange = (event) => {
    const { onChange, instituteDetails } = this.props;

    onChange({ ...instituteDetails, [event.target.name]: event.target.value });
  };

  handleAlertClose = () => {
    this.setState({ alertOpen: false });
  };

  handleProfilingReminder = () => {
    const { id } = this.props.instituteDetails;
    this.api
      .post(`/api/v1/institutes/${id}/profiling_reminder`)
      .then((data) => {
        const { message } = data.json;
        this.setState({ alertOpen: true, alertMessage: message });
      });
  };

  handleProgramRegistrationNotification = () => {
    const { id } = this.props.instituteDetails;
    this.api
      .post(
        `/api/v1/institutes/${id}/supervisor_program_registration_notification`
      )
      .then((data) => {
        const { message } = data.json;
        this.setState({ alertOpen: true, alertMessage: message });
      });
  };

  handleProfilesWaitiingNotification = () => {
    const { id } = this.props.instituteDetails;
    this.api
      .post(`/api/v1/institutes/${id}/supervisor_profiles_waiting_notification`)
      .then((data) => {
        const { message } = data.json;
        this.setState({ alertOpen: true, alertMessage: message });
      });
  };

  handleVcmSendNow = (suffix) => {
    const { id } = this.props.instituteDetails;
    this.api
      .post(`/api/v1/institutes/${id}/${suffix}`)
      .then((data) => {
        const { message } = data.json;
        this.setState({ alertOpen: true, alertMessage: message });
      });
  };

  getVCMSection = ({ title, enabledKey, enabledValue, frequencyKey, frequencyValue, frequencyOptions, introParagraphKey, introParagraphValue, sendNowUrlSuffix }) => {
    const { classes, makeFieldProps } = this.props;

    const hasFrequency = frequencyKey && frequencyValue && frequencyOptions

    return (
      <Paper className={classes.panelContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Typography variant="h6">{title}</Typography>
              <div className={classes.actionsContainer}>
                <DefaultCheckbox
                  name={enabledKey}
                  label="Enable reminder"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={enabledValue}
                />
                {sendNowUrlSuffix ? (<Tooltip
                  classes={{
                    popper: classes.popper,
                    tooltip: classes.tooltipContainer
                  }}
                  placement="top"
                  title={
                    <WorkshopReminder label="Send now will send this email notification immediately to all applicable educators, regardless of the configuration of the automated reminder." />
                  }
                >
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={() => this.handleVcmSendNow(sendNowUrlSuffix)}
                  >
                    <Typography variant="button">Send Now</Typography>
                  </Button>
                </Tooltip>) : null}
              </div>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {hasFrequency ? (<WaveSelectField
              label="Email frequency"
              menuItems={frequencyOptions}
              {...makeFieldProps(
                frequencyKey,
                frequencyValue
              )}
              onChange={this.handleInputChange}
            /> ) : null}
          </Grid>
          <Grid item xs={6}>
            <WaveTextArea
              label="Intro Paragraph"
              maxLength={1000}
              rows={4}
              placeholder="Enter reminder text for the notification email (1000 chars max)"
              {...makeFieldProps(
                introParagraphKey,
                introParagraphValue
              )}
              onChange={this.handleInputChange}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  };

  render() {
    const {
      classes,
      makeFieldProps,
      instituteDetails: {
        workshop_reminder_days,
        zero_points_reminder_days,
        partial_points_reminder_days,
        profiling_reminder_days,
        zero_points_reminder_enabled,
        partial_points_reminder_enabled,
        workshop_reminder_enabled,
        profiling_reminder_enabled,
        supervisor_profile_waiting_frequency,
        supervisor_profile_waiting_enabled,
        supervisor_program_registration_period,
        supervisor_program_registration_enabled,
        unactioned_points_reminder_enabled,
        unactioned_points_reminder_days,
        pending_registrations_reminder_enabled,
        pending_registrations_reminder_days,
        registrations_require_approval_enabled,
        vcm_enabled,
        vcm_missing_reminder_enabled,
        vcm_missing_reminder_period,
        vcm_missing_reminder_intro_paragraph,
        vcm_not_submitted_reminder_period,
        vcm_not_submitted_reminder_enabled,
        vcm_not_submitted_reminder_intro_paragraph,
        vcm_expired_reminder_period,
        vcm_expired_reminder_enabled,
        vcm_expired_reminder_intro_paragraph,
        vcm_approver_reminder_period,
        vcm_approver_reminder_enabled,
        vcm_approver_reminder_intro_paragraph,
        vcm_approver_returns_notification_enabled,
        vcm_approver_returns_notification_intro_paragraph,
        vcm_approval_notification_enabled,
        vcm_approval_notification_paragraph,
        vcm_approval_delegated_notification_enabled,
        vcm_approval_delegated_notification_paragraph,
      }
    } = this.props;

    const { alertOpen, serverity, alertMessage } = this.state;

    const profilingReminderOptions = {
      30: 30,
      60: 60,
      90: 90
    };

    const instituteMeta = this.meta.get('institutes');
    const supervisorProfileWaitingFrequencies =
      instituteMeta.supervisor_profile_waiting_frequency.options;
    const supervisorProgramRegistrationPeriods =
      instituteMeta.supervisor_program_registration_period.options;

    const VCMSections = vcm_enabled ? [
      {
        title: 'VCM Missing Reminder',
        enabledKey: 'vcm_missing_reminder_enabled',
        enabledValue: vcm_missing_reminder_enabled,
        frequencyKey: 'vcm_missing_reminder_period',
        frequencyValue: vcm_missing_reminder_period,
        frequencyOptions: instituteMeta.vcm_missing_reminder_period.options,
        introParagraphKey: 'vcm_missing_reminder_intro_paragraph',
        introParagraphValue: vcm_missing_reminder_intro_paragraph,
        sendNowUrlSuffix: 'vcm_missing_reminder_send_now',
      },
      {
        title: 'VCM Not Submitted Reminder',
        enabledKey: 'vcm_not_submitted_reminder_enabled',
        enabledValue: vcm_not_submitted_reminder_enabled,
        frequencyKey: 'vcm_not_submitted_reminder_period',
        frequencyValue: vcm_not_submitted_reminder_period,
        frequencyOptions: instituteMeta.vcm_not_submitted_reminder_period.options,
        introParagraphKey: 'vcm_not_submitted_reminder_intro_paragraph',
        introParagraphValue: vcm_not_submitted_reminder_intro_paragraph,
        sendNowUrlSuffix: 'vcm_not_submitted_reminder_send_now',
      },
      {
        title: 'VCM Expired Reminder',
        enabledKey: 'vcm_expired_reminder_enabled',
        enabledValue: vcm_expired_reminder_enabled,
        frequencyKey: 'vcm_expired_reminder_period',
        frequencyValue: vcm_expired_reminder_period,
        frequencyOptions: instituteMeta.vcm_expired_reminder_period.options,
        introParagraphKey: 'vcm_expired_reminder_intro_paragraph',
        introParagraphValue: vcm_expired_reminder_intro_paragraph,
        sendNowUrlSuffix: 'vcm_expired_reminder_send_now',
      },
      {
        title: 'VCM Approver Reminder',
        enabledKey: 'vcm_approver_reminder_enabled',
        enabledValue: vcm_approver_reminder_enabled,
        frequencyKey: 'vcm_approver_reminder_period',
        frequencyValue: vcm_approver_reminder_period,
        frequencyOptions: instituteMeta.vcm_approver_reminder_period.options,
        introParagraphKey: 'vcm_approver_reminder_intro_paragraph',
        introParagraphValue: vcm_approver_reminder_intro_paragraph,
        sendNowUrlSuffix: 'vcm_approver_reminder_send_now',
      },
      {
        title: 'Approver returns VCM notification',
        enabledKey: 'vcm_approver_returns_notification_enabled',
        enabledValue: vcm_approver_returns_notification_enabled,
        introParagraphKey: 'vcm_approver_returns_notification_intro_paragraph',
        introParagraphValue: vcm_approver_returns_notification_intro_paragraph,
        hasSendNow: false,
      },
      {
        title: 'VCM Approval Notification',
        enabledKey: 'vcm_approval_notification_enabled',
        enabledValue: vcm_approval_notification_enabled,
        introParagraphKey: 'vcm_approval_notification_paragraph',
        introParagraphValue: vcm_approval_notification_paragraph,
        hasSendNow: false,
      },
      {
        title: 'Approval Delegated',
        enabledKey: 'vcm_approval_delegated_notification_enabled',
        enabledValue: vcm_approval_delegated_notification_enabled,
        introParagraphKey: 'vcm_approval_delegated_notification_paragraph',
        introParagraphValue: vcm_approval_delegated_notification_paragraph,
        hasSendNow: false,
      }
    ] : []

    return (
      <Grid container spacing={2}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={alertOpen}
          autoHideDuration={3000}
          onClose={this.handleAlertClose}
        >
          <div>
            <Alert variant={serverity} message={alertMessage} onClose={this.handleAlertClose} />
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Points Achievement Reminder
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <WaveTextField
                  label="Zero Points Reminder (Days)"
                  tooltipProps={{
                    placement: 'top',
                    titleComponent: (
                      <WorkshopReminder label="The frequency for a reminder notification to be sent when zero points have been achieved" />
                    ),
                    tooltipContainer: classes.tooltipContainer,
                    tooltipPopper: classes.popper
                  }}
                  {...makeFieldProps(
                    'zero_points_reminder_days',
                    zero_points_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <WaveTextField
                  label="Partial Points Reminder (Days)"
                  tooltipProps={{
                    placement: 'top',
                    titleComponent: (
                      <WorkshopReminder label="The frequency for a reminder notification to be sent when partial points have been achieved" />
                    ),
                    tooltipContainer: classes.tooltipContainer,
                    tooltipPopper: classes.popper
                  }}
                  {...makeFieldProps(
                    'partial_points_reminder_days',
                    partial_points_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={6}>
                <DefaultCheckbox
                  name="zero_points_reminder_enabled"
                  label="Enable zero points reminder notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={zero_points_reminder_enabled}
                />
              </Grid>
              <Grid item xs={6}>
                <DefaultCheckbox
                  name="partial_points_reminder_enabled"
                  label="Enable partial points reminder notification?"
                  onChange={this.handleInputChange}
                  checkboxValue={true}
                  unCheckValue={false}
                  value={partial_points_reminder_enabled}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">Workshop Reminder</Typography>
                  <div className={classes.actionsContainer}>
                    <DefaultCheckbox
                      name="workshop_reminder_enabled"
                      label="Enable reminder"
                      onChange={this.handleInputChange}
                      checkboxValue={true}
                      unCheckValue={false}
                      value={workshop_reminder_enabled}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveTextField
                  label="Workshop Reminder (Days)"
                  tooltipProps={{
                    placement: 'top',
                    titleComponent: <WorkshopReminder />,
                    tooltipContainer: classes.tooltipContainer,
                    tooltipPopper: classes.popper
                  }}
                  {...makeFieldProps(
                    'workshop_reminder_days',
                    workshop_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">Profiling tool Reminder</Typography>
                  <div className={classes.actionsContainer}>
                    <DefaultCheckbox
                      name="profiling_reminder_enabled"
                      label="Enable reminder"
                      onChange={this.handleInputChange}
                      checkboxValue={true}
                      unCheckValue={false}
                      value={profiling_reminder_enabled}
                    />
                    <Tooltip
                      classes={{
                        popper: classes.popper,
                        tooltip: classes.tooltipContainer
                      }}
                      placement="top"
                      title={
                        <WorkshopReminder label="Send now will send this email notification immediately to all applicable educators, regardless of the configuration of the automated reminder." />
                      }
                    >
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        onClick={this.handleProfilingReminder}
                      >
                        <Typography variant="button">Send Now</Typography>
                      </Button>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveSelectField
                  label="Profiling tool Reminder (Days)"
                  menuItems={profilingReminderOptions}
                  {...makeFieldProps(
                    'profiling_reminder_days',
                    profiling_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">
                    Supervisor Profiles Waiting
                  </Typography>
                  <div className={classes.actionsContainer}>
                    <DefaultCheckbox
                      name="supervisor_profile_waiting_enabled"
                      label="Enable reminder"
                      onChange={this.handleInputChange}
                      checkboxValue={true}
                      unCheckValue={false}
                      value={supervisor_profile_waiting_enabled}
                    />
                    <Tooltip
                      classes={{
                        popper: classes.popper,
                        tooltip: classes.tooltipContainer
                      }}
                      placement="top"
                      title={
                        <WorkshopReminder label="Send now will send this email notification immediately to all applicable Supervisors, regardless of the configuration of the automated reminder." />
                      }
                    >
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        onClick={this.handleProfilesWaitiingNotification}
                      >
                        <Typography variant="button">Send Now</Typography>
                      </Button>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveSelectField
                  label="Email Frequency"
                  menuItems={supervisorProfileWaitingFrequencies}
                  {...makeFieldProps(
                    'supervisor_profile_waiting_frequency',
                    supervisor_profile_waiting_frequency
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">
                    Supervisor Program Registrations
                    <Tooltip
                      classes={{
                        popper: classes.tooltipPopper,
                        tooltip: classes.tooltipContainer
                      }}
                      placement="top"
                      title={
                        <WorkshopReminder label="This notification will report on Educators who have registered for programs in the past period." />
                      }
                    >
                      <span> ⓘ</span>
                    </Tooltip>
                  </Typography>
                  <div className={classes.actionsContainer}>
                    <DefaultCheckbox
                      name="supervisor_program_registration_enabled"
                      label="Enable reminder"
                      onChange={this.handleInputChange}
                      checkboxValue={true}
                      unCheckValue={false}
                      value={supervisor_program_registration_enabled}
                    />
                    <Tooltip
                      classes={{
                        popper: classes.popper,
                        tooltip: classes.tooltipContainer
                      }}
                      placement="top"
                      title={
                        <WorkshopReminder label="Send now will send this email notification immediately to all applicable Supervisors, regardless of the configuration of the automated reminder." />
                      }
                    >
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        onClick={this.handleProgramRegistrationNotification}
                      >
                        <Typography variant="button">Send Now</Typography>
                      </Button>
                    </Tooltip>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveSelectField
                  label="Period"
                  menuItems={supervisorProgramRegistrationPeriods}
                  {...makeFieldProps(
                    'supervisor_program_registration_period',
                    supervisor_program_registration_period
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.panelContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between">
                  <Typography variant="h6">
                    Unactioned Points Reminder
                  </Typography>
                  <div className={classes.actionsContainer}>
                    <DefaultCheckbox
                      name="unactioned_points_reminder_enabled"
                      label="Enable reminder"
                      onChange={this.handleInputChange}
                      checkboxValue={true}
                      unCheckValue={false}
                      value={unactioned_points_reminder_enabled}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <WaveTextField
                  label="Unactioned Points Reminder (Days)"
                  tooltipProps={{
                    placement: 'top',
                    titleComponent: (
                      <WorkshopReminder label="The frequency for a reminder notification to be sent when there are unactioned points requests" />
                    ),
                    tooltipContainer: classes.tooltipContainer,
                    tooltipPopper: classes.popper
                  }}
                  {...makeFieldProps(
                    'unactioned_points_reminder_days',
                    unactioned_points_reminder_days
                  )}
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
          </Paper>

          {registrations_require_approval_enabled && (
            <Paper className={classes.panelContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between">
                    <Typography variant="h6">
                      Unapproved Registrations Reminder
                    </Typography>
                    <div className={classes.actionsContainer}>
                      <DefaultCheckbox
                        name="pending_registrations_reminder_enabled"
                        label="Enable reminder"
                        onChange={this.handleInputChange}
                        checkboxValue={true}
                        unCheckValue={false}
                        value={pending_registrations_reminder_enabled}
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <WaveTextField
                    label="Unapproved Registrations Reminder (Days)"
                    tooltipProps={{
                      placement: 'top',
                      titleComponent: (
                        <WorkshopReminder label="The days before the program start date for a reminder notification to be sent when there are unapproved registrations" />
                      ),
                      tooltipContainer: classes.tooltipContainer,
                      tooltipPopper: classes.popper
                    }}
                    {...makeFieldProps(
                      'pending_registrations_reminder_days',
                      pending_registrations_reminder_days
                    )}
                    onChange={this.handleInputChange}
                  />
                </Grid>
              </Grid>
            </Paper>
          )}

          {VCMSections.map((section) => this.getVCMSection(section))}
        </Grid>
      </Grid>
    );
  }
}

const EnhancedNotification = formHelperHoc(
  withStyles(Notification, notificationStyles, { withTheme: true })
);

export { EnhancedNotification as Notification };
