import React from 'react';
import { withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AdminTable from '../../../common/components/table/AdminTable';
import ApiService from '../../../common/services/ApiService';
import VcmFilters from './VcmFilters';
import { vcmStatusOptions } from './options';

const styles = (theme) => ({
  exportHeader: {
    padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(
      25
    )} ${theme.typography.pxToRem(25)} ${theme.typography.pxToRem(25)}`
  },
  vcmsListHeadline: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(35)
  }
});

class VcmList extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.fields = props.institute.vocational_currency_uses_units
      ? [
          'owner_user.full_name',
          'revision_text',
          'created_at',
          'approved_at',
          'current_units_text',
          'status',
          'approver_user.full_name'
        ]
      : [
          'owner_user.full_name',
          'revision_text',
          'created_at',
          'approved_at',
          'status',
          'approver_user.full_name'
        ];
    this.state = {
      filters: { status_eq: 'in_review' },
      params: {},
      order: null,
      orderBy: null,
      filterOptionsFetched: false
    };
  }

  handleFilterOptionsFetched = () => {
    this.setState({ filterOptionsFetched: true });
  };

  handleApplyFilters = (filters, params) => {
    this.setState({ filters, params });
  };

  handleApplySorting = (order, orderBy) => {
    this.setState({ order: order, orderBy: orderBy });
  };

  handleExport = () => {
    const sortingParams = {
      direction: this.state.order,
      field: this.state.orderBy
    };
    const searchRequest = this.api.searchUrl(
      this.state.filters,
      { is_admin_app: true },
      null,
      sortingParams,
      null
    );

    let apiUrl = '';
    searchRequest !== ''
      ? (apiUrl = '/api/v1/vcms.csv?')
      : (apiUrl = '/api/v1/vcms.csv');
    const csvUrl = apiUrl + searchRequest;
    return csvUrl;
  };

  handleOnUserCellClicked = (row) => {
    this.props.history.push('users/' + row.owner_user.id);
  };

  handleOnRevisionCellClicked = (row) => {
    this.props.history.push('vcm/' + row.id);
  };

  render() {
    const {
      classes,
      institute: { vocational_currency_uses_units }
    } = this.props;
    const { filters, filterOptionsFetched, params } = this.state;

    let isTouch;
    if (typeof document !== 'undefined') {
      isTouch = 'ontouchstart' in document.documentElement;
    }

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.vcmsListHeadline} variant="h5">
            Manage VCMs
          </Typography>
          <Paper>
            <VcmFilters
              onOptionsFetched={this.handleFilterOptionsFetched}
              handleApplyFilters={this.handleApplyFilters}
              vcmStatusOptions={vcmStatusOptions}
              showCurrencyFilters={vocational_currency_uses_units}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              className={classes.exportHeader}
              justifyContent="space-between"
            >
              <Typography variant="h6">VCMs</Typography>
              <a href={`${this.handleExport()}`} target="_blank">
                <Button color="secondary" size="small" variant="contained">
                  <Typography variant="button">Export</Typography>
                </Button>
              </a>
            </Grid>
            {filterOptionsFetched ? (
              <AdminTable
                showTablePagination
                fields={this.fields}
                filters={filters}
                params={params}
                handleApplySorting={this.handleApplySorting}
                onCellClicked={{
                  ['owner_user.full_name']: this.handleOnUserCellClicked,
                  ['revision_text']: this.handleOnRevisionCellClicked
                }}
                resource="vcms"
              />
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(VcmList, styles, { withTheme: true });
