import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import SVGInline from "react-svg-inline"

import { withStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';

import InstitutePicker from './InstitutePicker';
import Account from './Account';
import ChangePassword from "./ChangePassword";
import ApiService from "../../../common/services/ApiService";

const programNavIcon = require('../../../images/progam-nav.svg.raw');
const profilingNavIcon = require('../../../images/profiling-nav.svg.raw');
const reportsNavIcon = require('../../../images/reports-nav.svg.raw');
const usersNavIcon = require('../../../images/users-nav.svg.raw');
const instituteNavIcon = require('../../../images/institute-nav.svg.raw');
const accountNavIcon = require('../../../images/account-nav.svg.raw');
const registrationIcon = require('../../../images/registration-nav.svg.raw');
const vcmNavIcon = require('../../../images/vcm-nav.svg.raw');

const styles = theme => ({
  header: {
    background: 'white',
    paddingLeft: 80,
    paddingRight: 80,
    height: 75,
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)'
  },
  menuItem: {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center',
    float: 'right',
    marginTop: 15,
    minWidth: 60,
    marginLeft: 20,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main
    },
    '&.active span': {
      color: theme.palette.secondary.main
    },
    '&.active svg path': {
      fill: theme.palette.secondary.main
    },
    '&.active svg g': {
      stroke: theme.palette.secondary.main
    }
  },
  menuItemImg: {
    display: 'inline-block',
    '& svg path': {
      fill: theme.palette.primary.main
    },
    '& svg g': {
      stroke: theme.palette.primary.main
    }
  }
});

class Header extends React.Component {

  state = {
    anchorEl: null,
    changePasswordOpen: false,
    instituteDetails: {}
  };

  constructor(props) {
    super(props);
    this.api = new ApiService();
  }

  getInstituteDetails = () => {
    this.api.get('institutes', this.props.institute.id)
      .then((response) => {
        this.setState({ instituteDetails: response.json.data });
      })
  }

  componentDidMount() {
    this.getInstituteDetails();
  }

  render() {
    const { classes, user } = this.props;
    const { registrations_require_approval_enabled, vcm_enabled } = this.state.instituteDetails;
    const profilingEnabled = user.is_institute_admin || user.is_super_admin || user.is_supervisor;
    const reportsEnabled = user.is_institute_admin || user.is_super_admin || user.is_supervisor;
    const usersEnabled = user.is_institute_admin || user.is_super_admin || user.is_supervisor;
    const viewInstitutesEnabled = user.is_institute_admin || user.is_super_admin;
    const registrationsEnabled = registrations_require_approval_enabled && (user.is_institute_admin || user.is_super_admin || user.is_supervisor);
    const vcmEnabled = (user.is_institute_admin || user.is_super_admin || user.is_supervisor) && vcm_enabled;

    return (
      <div className={classes.header}>
        { user.is_super_admin ?
          (<InstitutePicker institute={this.props.institute} institutes={this.props.institutes}  onInstituteChange={this.props.onInstituteChange} />)
        :
        (<img style={{height: 35, marginTop: 20, float: 'left'}}  src={this.props.institute.logo_url} />)
        }
        <div>
          <MenuItem
            classes={classes}
            component='div'
            to="/"
            svg={accountNavIcon}
            onClick={(event) => this.setState({ anchorEl: event.currentTarget })}
          >
            Account
          </MenuItem>
          { viewInstitutesEnabled && <NavItem classes={classes} to={`/institutes/${this.props.institute.id}`} svg={instituteNavIcon}>Institute</NavItem>}
          { usersEnabled && <NavItem userIsActive={this.userIsActive} classes={classes} to="/users" svg={usersNavIcon}>Users</NavItem> }
          { reportsEnabled && <NavItem classes={classes} to="/reports" svg={reportsNavIcon}>Reports</NavItem> }
          { vcmEnabled && <NavItem classes={classes} to="/vcm" svg={vcmNavIcon} notification={this.props.vcmsInReviewCount > 0}>VCM</NavItem> }
          { profilingEnabled && <NavItem classes={classes} to="/profiling" svg={profilingNavIcon} notification={this.props.openProfilesCount > 0}>Profiling</NavItem> }
          { registrationsEnabled && <NavItem classes={classes} to="/registrations" svg={registrationIcon} notification={this.props.pendingRegistrationsCount > 0}>Registrations</NavItem> }
          <NavItem classes={classes} to="/programs" svg={programNavIcon}>Programs</NavItem>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleMenuClose} >
          <Account user={user} onChangePassword={this.handleChangePassword}/>
        </Menu>
        <ChangePassword open={this.state.changePasswordOpen} onClose={() => this.setState({changePasswordOpen: false})}/>
      </div>
    );
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChangePassword = () => {
    this.setState({anchorEl: null, changePasswordOpen: true});
  };

  userIsActive = (match, location) => {
    const urlRegex = /^\/users.*/g;
    const currentLocation = location.pathname;
    const isUser = urlRegex.test(currentLocation);

    if (isUser) {
      return true;
    }

  };

}

const NavItem = (props) => {
  return (
    <NavLink activeClassName='active' className={props.classes.menuItem} to={props.to} isActive={props.userIsActive}>
      { props.notification && <div style={{position: 'absolute', top: 2, left: 12, background: '#f00', width: 9, height: 9, borderRadius: "50%"}}></div> }
      <SVGInline className={props.classes.menuItemImg} svg={props.svg} /><br />
      <Typography component="span" color="primary" style={{display: 'block', fontSize: 13, lineHeight: 1}}>{props.children}</Typography>
    </NavLink>
  );
};

const MenuItem = (props) => {
  return (
    <a className={props.classes.menuItem} onClick={props.onClick}>
      <SVGInline className={props.classes.menuItemImg} svg={props.svg} /><br />
      <Typography component="span" color="primary" style={{display: 'block', fontSize: 13, lineHeight: 1}}>{props.children}</Typography>
    </a>
  );
};

export default withStyles(Header, styles);
