import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import ApiService from '@common/services/ApiService';
import EntityManage from '@common/components/EntityManage';
import VcmSummary from './VcmSummary';
import VcmUnitsFilter from './VcmUnitsFilter';
import { Button, Paper, Typography } from '@mui/material';
import VcmCourses from './VcmCourses';
import VcmUnitResponses from './VcmUnitResponses';
import VcmCourseRemovedUnits from './VcmCourseRemovedUnits';
import { vcmStatusOptions, vcmUnitResponseStatusOptions } from './options';
import VcmRejectModal from './VcmRejectModal';
import ErrorToast from './ErrorToast';
import VcmApproveModal from './VcmApproveModal';
import VcmUpdateStatusModal from './VcmUpdateStatusModal';
import VcmDelegateApproverModal from './VcmDelegateApproverModal';

const SINGLE_UNIT_ID = 'single-unit';
const FILTER_STATUS_LIST = [
  vcmUnitResponseStatusOptions.in_review.id,
  vcmUnitResponseStatusOptions.approved.id,
  vcmUnitResponseStatusOptions.needs_attention.id,
  'previously_approved'
];

const INITIAL_FILTERS = {
  removed_eq: false,
  extended_status_in: [vcmUnitResponseStatusOptions.in_review.id]
};

const styles = (theme) => ({
  vcmDetailsContainer: {
    marginTop: theme.typography.pxToRem(25)
  },
  summaryHeadline: {
    marginBottom: theme.typography.pxToRem(16)
  },
  vcmStatus: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.33,
    textTransform: 'uppercase'
  },
  unitsCountsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px'
  },
  textButton: {
    textTransform: 'none'
  }
});

class VcmDetails extends EntityManage {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      vcmId: props.match.params.id,
      errorMessage: null,
      vcm: null,
      vcmCourses: [],
      courses: [],
      allUnitResponses: [],
      filteredUnitResponses: [],
      removedUnits: [],
      selectedCourseId: null,
      filters: INITIAL_FILTERS,
      params: {},
      refreshTick: 0,
      isRejectModalOpened: false,
      isApproveModalOpened: false,
      isUpdateStatusModalOpened: false,
      isDelegateApproverModalOpened: false
    };
  }

  componentDidMount() {
    this.getVcmDetailsData();
  }

  getVcmDetailsData = async () => {
    try {
      const getVcmPromise = this.api.get('vcms', this.state.vcmId);
      const getUnitResponsesPromise = this.api.get(
        'vcms',
        this.state.vcmId + '/unit_responses'
      );
      const getVcmCoursesPromise = this.api.get(
        'vcms',
        this.state.vcmId + '/courses'
      );
      const [vcm, unitResponses, vcmCourses] = await Promise.all([
        getVcmPromise,
        getUnitResponsesPromise,
        getVcmCoursesPromise
      ]);

      const coursesAndSingleUnit = vcmCourses.json.data
        .map(({ course }) => course)
        .concat({ id: SINGLE_UNIT_ID, code: 'Single Unit' });

      const selectedCourseId =
        this.state.selectedCourseId || coursesAndSingleUnit[0].id;
      const allUnitResponses = unitResponses.json.data;
      const filteredUnitResponses = allUnitResponses.filter(
        (unitResponse) => !unitResponse.removed
      );
      const removedUnits = this.getRemovedUnits({
        selectedCourseId,
        vcmCourses: vcmCourses.json.data,
        allUnitResponses
      });
      this.setState({
        vcm: vcm.json.data,
        vcmCourses: vcmCourses.json.data,
        courses: coursesAndSingleUnit,
        allUnitResponses,
        removedUnits,
        filteredUnitResponses,
        selectedCourseId,
        params: {
          course_id: selectedCourseId === SINGLE_UNIT_ID ? '' : selectedCourseId
        },
        refreshTick: Date.now()
      });
    } catch (error) {
      this.setState({
        errorMessage: 'Something went wrong, please try again'
      });
    }
  };

  getRemovedUnits = ({ selectedCourseId, vcmCourses, allUnitResponses }) => {
    const vcmCourse = vcmCourses.find(
      (vcmCourse) => vcmCourse.course.id === selectedCourseId
    );

    if (vcmCourse) {
      return allUnitResponses
        .filter(
          (unitResponse) =>
            unitResponse.removed &&
            vcmCourse.unit_ids?.includes(unitResponse.unit.id)
        )
        .map(({ unit }) => unit);
    }

    return [];
  };

  handleCourseTabChange = async (_, newValue) => {
    this.setState({
      params: {
        ...this.state.params,
        course_id: newValue === SINGLE_UNIT_ID ? '' : newValue
      }
    });
    const removedUnits = await this.getRemovedUnits({
      selectedCourseId: newValue,
      allUnitResponses: this.state.allUnitResponses,
      vcmCourses: this.state.vcmCourses
    });
    this.setState((currentState) => ({
      ...currentState,
      selectedCourseId: newValue,
      removedUnits
    }));
  };

  transformFilters = (filters) => {
    return FILTER_STATUS_LIST.reduce((accumulator, key) => {
      accumulator = { ...accumulator, [key]: filters.extended_status_in.includes(key) };
      return accumulator;
    }, {});
  };

  handleApplyFilter = (filters) => {
    const enabledFilters = Object.keys(filters).filter(
      (key) => filters[key]
    );

    this.setState({
      filters: {
        ...this.state.filters,
        extended_status_in: enabledFilters
      }
    });
  };

  handleRefresh = () => {
    this.getVcmDetailsData();
  };

  processResponse(res) {
    // Calls the default
    super.processResponse(res);

    // Custom stuff
    if (res.status == 201) {
      this.props.history.push('/vcm/' + res.json.data.id);
    }
  }

  openRejectModal = () => {
    this.setState({ isRejectModalOpened: true });
  };

  handleCloseRejectModal = () => {
    this.setState({ isRejectModalOpened: false });
  };

  openApproveModal = () => {
    this.setState({ isApproveModalOpened: true });
  };

  handleCloseApproveModal = () => {
    this.setState({ isApproveModalOpened: false });
  };

  openUpdateStatusModal = () => {
    this.setState({ isUpdateStatusModalOpened: true });
  };

  handleCloseUpdateStatusModal = () => {
    this.setState({ isUpdateStatusModalOpened: false });
  };

  openDelegateApproverModal = () => {
    this.setState({ isDelegateApproverModalOpened: true });
  };

  handleCloseDelegateApproverModal = () => {
    this.setState({ isDelegateApproverModalOpened: false });
  };

  render() {
    const { classes } = this.props;
    const {
      vcm,
      filters,
      params,
      filteredUnitResponses,
      courses,
      selectedCourseId,
      refreshTick,
      removedUnits,
      isRejectModalOpened,
      isApproveModalOpened,
      isUpdateStatusModalOpened,
      isDelegateApproverModalOpened,
      errorMessage
    } = this.state;

    if (!vcm) {
      return null;
    }

    const vcmStatusText = vcmStatusOptions[vcm.status]?.label ?? vcm.status;
    return (
      <>
        <Grid container className={classes.vcmDetailsContainer} spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.vcmStatus} color="primary">
              {vcmStatusText}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <VcmSummary
              vcm={vcm}
              classes={classes}
              unitResponses={filteredUnitResponses}
            />
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-start"
            gap={3}
          >
            <div>
              <Button
                className={classes.textButton}
                onClick={this.openDelegateApproverModal}
              >
                Delegate Approver
              </Button>
              <Button
                className={classes.textButton}
                onClick={this.openUpdateStatusModal}
              >
                Update Status
              </Button>
              <Button
                component={Link}
                to={`/vcm/${vcm.id}/history`}
                className={classes.textButton}
              >
                History
              </Button>
            </div>
            <Button variant="contained" onClick={this.openRejectModal}>
              Return VCM To Educator
            </Button>
            <Button variant="contained" onClick={this.openApproveModal}>
              Approve VCM
            </Button>
          </Grid>
          <Grid item xs={12} my={2}>
            <Paper>
              <VcmUnitsFilter
                initialFilters={this.transformFilters(INITIAL_FILTERS)}
                onApplyFilters={this.handleApplyFilter}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <VcmCourses
              courses={courses}
              onTabChange={this.handleCourseTabChange}
              selectedCourseId={selectedCourseId}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <VcmUnitResponses
                vcm={vcm}
                institute={this.props.institute}
                refreshTick={refreshTick}
                filters={filters}
                params={params}
                onRefresh={this.handleRefresh}
              />
              <VcmCourseRemovedUnits units={removedUnits} />
            </Paper>
          </Grid>
        </Grid>
        <VcmRejectModal
          isOpened={isRejectModalOpened}
          onClose={this.handleCloseRejectModal}
          unitResponses={filteredUnitResponses}
          vcm={vcm}
          onRefresh={this.handleRefresh}
        />
        <VcmApproveModal
          isOpened={isApproveModalOpened}
          onClose={this.handleCloseApproveModal}
          unitResponses={filteredUnitResponses}
          vcm={vcm}
          onRefresh={this.handleRefresh}
        />
        <VcmUpdateStatusModal
          isOpened={isUpdateStatusModalOpened}
          onClose={this.handleCloseUpdateStatusModal}
          vcm={vcm}
          onRefresh={this.handleRefresh}
        />
        <VcmDelegateApproverModal
          isOpened={isDelegateApproverModalOpened}
          onClose={this.handleCloseDelegateApproverModal}
          vcm={vcm}
          onRefresh={this.handleRefresh}
        />
        <ErrorToast
          open={!!errorMessage}
          errorMessage={errorMessage}
          onClose={() => this.setState({ errorMessage: null })}
        />
      </>
    );
  }
}

export default withStyles(VcmDetails, styles, { withTheme: true });
